import { Component } from '@angular/core';

@Component({
  selector: 'thaipro-tour-welcome',
  templateUrl: './tour-welcome.component.html',
  styleUrl: './tour-welcome.component.scss'
})
export class TourWelcomeComponent {

}
