import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnDestroy, OnInit, afterNextRender, afterRender, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HeaderDialogComponent } from '../../../../standalone/header-dialog/header-dialog.component';

@Component({
  selector: 'thaipro-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  constructor(private dialog: MatDialog, private injector: Injector) {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(HeaderDialogComponent, { width: '100%',
    });
  }

}
