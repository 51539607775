import { Component } from '@angular/core';

@Component({
  selector: 'thaipro-header-dialog',
  standalone: true,
  imports: [],
  templateUrl: './header-dialog.component.html',
  styleUrl: './header-dialog.component.scss'
})
export class HeaderDialogComponent {

}
