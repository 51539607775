<header class="d-flex flex-column">
    <nav class="menu d-flex justify-between">
        <div class="logo">
            <img src="/assets/images/logo.png" alt="#">
        </div>
        <div class="actions d-flex">
            <div class="geo d-flex items-center">
                <thaipro-icon name="Subtract"></thaipro-icon>
                <div class="geo-name fw-600">Пхукет</div>
            </div>
            <div class="language d-flex items-center">
                <thaipro-icon name="flagthailand"></thaipro-icon>
            </div>
            <div class="currency d-flex items-center">
                <div class="curr-container">
                    <thaipro-icon name="curr"></thaipro-icon>
                </div>
                <div class="curr-name fw-600">USD</div>
            </div>
            <div class="buttons d-flex items-center">
                <button mat-fab class="search d-flex items-center"><mat-icon>search</mat-icon></button>
                <button mat-flat-button class="account fw-500 fz-14"><mat-icon>person</mat-icon> Войти </button>
            </div>
        </div>
        <div class="mobile-actions">
            <button mat-icon-button><mat-icon>search</mat-icon></button>
            <button mat-icon-button><mat-icon>person</mat-icon></button>
            <button mat-icon-button (click)="openDialog()">
                <thaipro-icon name="menutrigger" class="menu-trigger"></thaipro-icon>
            </button>
        </div>
    </nav>
    <ul class="desktop-menu d-flex">
        <li class="desktop-menu-item d-flex">
            <div>Недвижимость</div>
            <div class="icon-container">
                <thaipro-icon name="down"></thaipro-icon>
            </div>
        </li>
        <li class="desktop-menu-item d-flex">
            <div>Авто и байки</div>
            <div class="icon-container">
                <thaipro-icon name="down"></thaipro-icon>
            </div>
        </li>
        <li class="desktop-menu-item"><div>Афиша</div></li>
        <li class="desktop-menu-item"><div>Экскурсии</div></li>
        <li class="desktop-menu-item"><div>Аренда яхт</div></li>
        <li class="desktop-menu-item"><div>Услуги</div></li>
        <li class="desktop-menu-item"><div>Визы</div></li>
    </ul>
</header>