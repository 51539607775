import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TOURPATHS } from './modules/tour/tour-names.routes';
import { STATICPATHS } from './modules/static/static-names.routes';
import { SERVICESPATH } from './modules/services/static-names.routes';
import { YACHTPATHS } from './modules/yachts/yachts-names.routes';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/main/main.module').then((mod) => mod.MainModule)
  },
  {
    path: TOURPATHS.tour,
    loadChildren: () => import('./modules/tour/tour.module').then((mod) => mod.TourModule)
  },
  {
    path: STATICPATHS.staticMain,
    loadChildren: () => import('./modules/static/static.module').then((mod) => mod.StaticModule)
  },
  {
    path: SERVICESPATH.services,
    loadChildren: () => import('./modules/services/services.module').then((mod) => mod.ServicesModule)
  },
  {
    path: YACHTPATHS.yachts,
    loadChildren: () => import('./modules/yachts/yachts.module').then((mod) => mod.YachtsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
