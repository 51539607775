<div class="d-flex justify-start">
    <h1 class="fw-700">Экскурсии в Таиланде</h1>
</div>

<p class="fw-500" class="title-text">
    Мы находим лучших гидов и совместно придумываем экскурсии. В результате вы встречаетесь с журналистами, историками, архитекторами и другими интересными людьми, которые умеют увлечь своими знаниями.
</p>

<div class="banner-container">
    <div class="d-flex justify-center input-wrap">
        <form class="destination-search" role="search">
            <label for="destination-input" class="visually-hidden">Куда вы собираетесь</label>
            <input type="text" id="destination-input" class="search-input" placeholder="Куда вы собираетесь" aria-label="Куда вы собираетесь">
            <button type="submit" class="search-button" aria-label="Search"></button>
        </form>
    </div>

    <div class="d-flex banner-text justify-between">
        <div class="banner-text-container">
            <div class="fw-600 tittle-banner-text">Лучшая команда гидов</div>
            <div class="fw-400 banner-info">Вся магия в личном общении.<br>
                Мы находим лучших гидов, для которых увлекать  — <br> это призвание!</div>
        </div>
        <div class="banner-text-container">
            <div class="fw-600 tittle-banner-text">Дружеские экскурсии</div>
            <div class="fw-400 banner-info">Проводим только качественные индивидуальные <br> и групповые экскурсии на русском языке.</div>
        </div>
        <div class="banner-text-container">
            <div class="fw-600 tittle-banner-text">673 199 отзывов</div>
            <div class="fw-400 banner-info">Нас любят путешественники. В 2023 году на наших <br> экскурсиях побывало 1 499 785 человек.</div>
        </div>
    </div>
</div>