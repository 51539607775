import { Component } from '@angular/core';

@Component({
  selector: 'thaipro-popular-cities',
  templateUrl: './popular-cities.component.html',
  styleUrl: './popular-cities.component.scss'
})
export class PopularCitiesComponent {

}
