import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { provideClientHydration, withEventReplay } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { IconComponent } from './components/icon/icon.component';
import { MatButtonModule } from '@angular/material/button';
import { TourWelcomeComponent } from './components/tour-welcome/tour-welcome.component';
import { PopularCitiesComponent } from './components/popular-cities/popular-cities.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ToursCarouselComponent } from './components/tours-carousel/tours-carousel.component';
import { CardComponent } from '../../standalone/card/card.component';
import { BlockTittleComponent } from './components/block-tittle/block-tittle.component';
import { GuideCardComponent } from './components/guide-card/guide-card.component';
import { GuidesComponent } from './components/guides/guides.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    IconComponent,
    TourWelcomeComponent,
    PopularCitiesComponent,
    ToursCarouselComponent,
    BlockTittleComponent,
    GuideCardComponent,
    GuidesComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    CardComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    TourWelcomeComponent,
    PopularCitiesComponent,
    ToursCarouselComponent,
    CardComponent,
    BlockTittleComponent,
    GuideCardComponent,
    GuidesComponent
  ],
  providers: [
    provideClientHydration(withEventReplay()),
    provideHttpClient(withFetch()),
  ]
})
export class SharedModule { }
