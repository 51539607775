import { Component, Input } from '@angular/core';

@Component({
  selector: 'thaipro-block-tittle',
  templateUrl: './block-tittle.component.html',
  styleUrl: './block-tittle.component.scss'
})
export class BlockTittleComponent {
  @Input() title: string = '';
  @Input() description: string | null = null;

}
