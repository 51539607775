<div class="title d-flex justify-between">
    <thaipro-block-tittle [title]="'Частные гиды в Таиланде'" ></thaipro-block-tittle>
    <div class="buttons-title d-flex">
        <button class="bg-grayE9" mat-icon-button><mat-icon>keyboard_arrow_left</mat-icon></button>
        <button class="bg-grayE9" mat-icon-button><mat-icon>keyboard_arrow_right</mat-icon></button>
    </div>
</div>

<section class="location-tag">
    <article class="tag-item">
      <div class="tag-content">
        <h3 class="tag-name">Rawai</h3>
        <span class="tag-count">(506)</span>
      </div>
    </article>
    <article class="tag-item">
      <div class="tag-content">
        <h3 class="tag-name">Chalong</h3>
        <span class="tag-count">(411)</span>
      </div>
    </article>
    <article class="tag-item">
      <div class="tag-content">
        <h3 class="tag-name">Chalong</h3>
        <span class="tag-count">(411)</span>
      </div>
    </article>
    <article class="tag-item">
      <div class="tag-content">
        <h3 class="tag-name">Wichit</h3>
        <span class="tag-count">(288)</span>
      </div>
    </article>
    <article class="tag-item">
      <div class="tag-content">
        <h3 class="tag-name">Wichit</h3>
        <span class="tag-count">(288)</span>
      </div>
    </article>
    <article class="tag-item">
      <div class="tag-content">
        <h3 class="tag-name">Ko Kaeo</h3>
        <span class="tag-count">(147)</span>
      </div>
    </article>
    <article class="tag-item">
      <div class="tag-content">
        <h3 class="tag-name">Talat Nuea</h3>
        <span class="tag-count">(95)</span>
      </div>
    </article>
    <article class="tag-item">
      <div class="tag-content">
        <h3 class="tag-name">Talat Nuea</h3>
        <span class="tag-count">(95)</span>
      </div>
    </article>
    <article class="tag-item">
      <div class="tag-content">
        <h3 class="tag-name">Ratsada</h3>
        <span class="tag-count">(74)</span>
      </div>
    </article>
</section>

<div class="guides-list d-flex flex-wrap justify-between">
    <thaipro-guide-card></thaipro-guide-card>
    <thaipro-guide-card></thaipro-guide-card>
    <thaipro-guide-card></thaipro-guide-card>
    <thaipro-guide-card></thaipro-guide-card>
    <thaipro-guide-card></thaipro-guide-card>
    <thaipro-guide-card></thaipro-guide-card>
</div>

<div class="open-btn-wrapper d-flex justify-center">
    <button mat-button>Показать ещё</button>
</div>