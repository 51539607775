 
<section class="guide-profile">
    <div class="profile-image-wrapper">
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/7615a580b30bc994432a0eef0b09150591ac53cf7654af195191594d8e9b6d87?apiKey=ebe69295369b48da88d42feb71473570&" class="profile-image" alt="Guide profile picture" />
    </div>
    <div class="profile-details">
    <div class="profile-header">
        <div class="name-rating-wrapper">
        <h2 class="guide-name">Сабри</h2>
        <div class="rating-container">
            <div class="rating-wrapper">
            <div class="rating">
                <div class="rating-stars">
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/f7f09d4a9ec52365a26a09a96826bd46ac61522c4d58fd986442c8786f65f930?apiKey=ebe69295369b48da88d42feb71473570&" class="star-icon" alt="" />
                <span class="rating-value">4.88</span>
                </div>
            </div>
            <div class="review-count">2271 отзыв</div>
            </div>
        </div>
        </div>
        <div class="guide-info">
        <div class="guide-location">
            <div class="location-icon-wrapper">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a71168a483b1b19878e4f4ddbe58eadded6aa2546264bdf9e4d09a0f88d610c1?apiKey=ebe69295369b48da88d42feb71473570&" class="location-icon" alt="" />
            </div>
            <span class="location-text">Гид в Пхукете</span>
        </div>
        <div class="visitor-count">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/bd03caba83d43f3d9370e2283688389e286ec64def33ee941c7ac750977277ad?apiKey=ebe69295369b48da88d42feb71473570&" class="visitor-icon" alt="" />
            <span>20 280 посетили</span>
        </div>
        </div>
        <div class="guide-description">
        <p class="description-text">
            Здравствуйте! Меня зовут Сабри. Я много лет жил в Стамбуле и собрал команду влюблённых в свой город гидов. Сейчас я живу на Пхукете и организовываю экскурсии и здесь! Я хорошо говорю по-русски и вместе со своей командой гидов с радостью
        </p>
        </div>
    </div>
    <button class="offer-button">35 предложений</button>
    </div>
</section>