import { Component } from '@angular/core';

@Component({
  selector: 'thaipro-guide-card',
  templateUrl: './guide-card.component.html',
  styleUrl: './guide-card.component.scss'
})
export class GuideCardComponent {
  
}
