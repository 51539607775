<div class="tittle d-flex justify-between">
    <thaipro-block-tittle [title]="title" [description]="description"></thaipro-block-tittle>
    <div class="buttons d-flex">
        <button class="bg-grayE9" mat-icon-button><mat-icon>keyboard_arrow_left</mat-icon></button>
        <button class="bg-grayE9" mat-icon-button><mat-icon>keyboard_arrow_right</mat-icon></button>
    </div>
</div>

<div class="d-flex cards">
    <thaipro-card></thaipro-card>
    <thaipro-card class="hidden"></thaipro-card>
    <thaipro-card class="hidden"></thaipro-card>
    <thaipro-card class="hidden"></thaipro-card>
</div>