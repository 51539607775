import { Component } from '@angular/core';

@Component({
  selector: 'thaipro-guides',
  templateUrl: './guides.component.html',
  styleUrl: './guides.component.scss'
})
export class GuidesComponent {

}
