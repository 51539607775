<div class="d-flex justify-start">
    <h2 class="fw-700">Популярные города</h2>
</div>

<p class="fw-500" class="title-text">
    Explore your real estate options across different property markets in Thailand
</p>
    
<section class="location-cards">
    <div class="card-row">
    <article class="location-card card-wide">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/445425d15664fc52ec3958615bed8379bec61364ec8d9c2a797fb55f2c0c184e?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="Bangkok cityscape" class="card-image" />
        <div class="card-content">
        <h2 class="location-name">Bangkok</h2>
        <p class="tour-count">544 экскурсий</p>
        </div>
    </article>
    <article class="location-card card-medium">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/e71442e2009850f3aab531af3c957592e5a0a34eeb8c18641807ec9867ceb1b2?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="Sathon district" class="card-image" />
        <div class="card-content">
        <h2 class="location-name">Sathon</h2>
        <p class="tour-count">12 экскурсий</p>
        </div>
    </article>
    <article class="location-card card-narrow">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/ccb0b96414f3c31a1e2845677d808acb9c9f1f3d166d83e99db3c149fa42fdf5?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="Asoke area" class="card-image" />
        <div class="card-content">
        <h2 class="location-name">Asoke</h2>
        <p class="tour-count">344 экскурсий</p>
        </div>
    </article>
    <article class="location-card card-medium">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/079ceb6444aed41c57b491c73391f07e3ac24032aff1833242dd0da8b875fddd?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="Patong beach" class="card-image" />
        <div class="card-content">
        <h2 class="location-name">Patong</h2>
        <p class="tour-count">78 экскурсий</p>
        </div>
    </article>
    </div>
    <div class="card-row">
    <article class="location-card">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/6d77dc30d3822dd15e72452760b97856ef8218259d292b4757578907a50e41d7?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="Sukhumvit road" class="card-image" />
        <div class="card-content">
        <h2 class="location-name">Sukhumvit</h2>
        <p class="tour-count">114 экскурсий</p>
        </div>
    </article>
    <article class="location-card">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/e5cba3123c3b3cce028b97d6fd066fd5ae9150e9417ecbbe4c835de3e670c4a9?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="Thong Lo BTS station" class="card-image" />
        <div class="card-content">
        <h2 class="location-name">Thong Lo BTS</h2>
        <p class="tour-count">115 экскурсий</p>
        </div>
    </article>
    <article class="location-card">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/6e125a4eec37bad56bb7755efb5c12477ce7c55ab51ad0edf022aea586f2a602?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="Phuket island" class="card-image" />
        <div class="card-content">
        <h2 class="location-name">Phuket</h2>
        <p class="tour-count">312 экскурсий</p>
        </div>
    </article>
    <article class="location-card card-wide">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/cfcb47f25a76d82a70f14cde977cf428015f9a938f451e01f89cbabff686390a?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="Laguna area" class="card-image" />
        <div class="card-content">
        <h2 class="location-name">Laguna</h2>
        <p class="tour-count">154 экскурсий</p>
        </div>
    </article>
    </div>
</section>