<footer class="cv__footer footer cv-content-wrapper">
    <div class="footer__content">
        <div class="footer__content-item">
            <img src="/assets/images/logo.png" alt="#" id="footer-logo">
        </div>
        <div class="footer__content-item hidden">
            <div class="fw-700 color-black2C">Недвижимость</div>
            <div class="fw-400 color-black63">Аренда недвижимости</div>
            <div class="fw-400 color-black63">Покупка недвижимости</div>
            <div class="fw-400 color-black63">Покупка земли</div>
        </div>
        <div class="footer__content-item hidden">
            <div class="fw-700 color-black2C">Авто и байки</div>
            <div class="fw-400 color-black63">Покупка авто и байков</div>
            <div class="fw-400 color-black63">Аренда авто и байков</div>
        </div>
        <div class="footer__content-item hidden">
            <div class="fw-700 color-black2C">Прочее</div>
            <div class="fw-400 color-black63">Афиша</div>
            <div class="fw-400 color-black63">Экскурсии</div>
            <div class="fw-400 color-black63">Аренда яхт</div>
            <div class="fw-400 color-black63">Услуги</div>
            <div class="fw-400 color-black63">Визы</div>
        </div>
        <div class="footer__content-item">
            <div class="fw-700 color-black2C">О нас</div>
            <div class="fw-400 color-black63">Контакты</div>
            <div class="fw-400 color-black63">Сотрудничество</div>
        </div>
        <div class="footer__content-item">
            <div class="fw-700 color-black2C">Свяжитесь с нами</div>
            <div class="fw-400 color-black63">+7 495 149 05 00</div>
            <div class="fw-400 color-black63">+66 2 132 1888</div>
            <div class="fw-400 color-black63">supportthai.pro</div>
        </div>
    </div>
    <div class="copyright"><div class="copy_one">Copyright 2024 © «Thai.PRO». All right reserved.</div><div class="copy_two">Политика конфиденциальности</div></div>
</footer>