import { Component, Input } from '@angular/core';

@Component({
  selector: 'thaipro-tours-carousel',
  templateUrl: './tours-carousel.component.html',
  styleUrl: './tours-carousel.component.scss'
})
export class ToursCarouselComponent {
  @Input() title: string = '';
  @Input() description: string = '';
}
