

<article class="tour-card">
    <div class="tour-image-wrapper">
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/6780cba483bd52787de2e193adc364fdbe1b30c9d1f09c9da93ce7b78c95d1d5?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="Tour destination scenery" class="tour-image" />
        <div class="tour-content">
            <div class="tour-header">
            <div class="discount-badge">
                15% скидка <span>(еще 32 часа)</span>
            </div>
            <button class="favorite-button" aria-label="Add to favorites">
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/4c174ff18c4abdcdca1439d9169af996fd3fa60e2c7cbaaee9e78afe45e9a6ca?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="" class="favorite-icon" />
            </button>
            </div>
            <div class="tour-details">
            <div class="tour-type">индивидуальная</div>
            <div class="tour-info">
                <div class="info-item">
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/86825e368a608776dc8c8434982dff4a7bf61255a67d2513134fbf8c264a5028?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="" class="info-icon" />
                <span>2 часа</span>
                </div>
                <div class="info-item">
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/f9945ef97beab29a4969a354087d267527736fa51f2e846c40daf5f30e5de31f?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="" class="info-icon" />
                <span>на авто</span>
                </div>
            </div>
            </div>
        </div>
        </div>
        <div class="tour-panel">
        <div class="tour-meta">
            <div class="location">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/d84d27c730724a45c05dd2716e605bf9fc12185e364c43db5dcbe52e517563e3?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="" class="location-icon" />
            <span class="location-name">Пхукет</span>
            </div>
            <div class="tour-rating">
            <span class="review-count">49 отзывов</span>
            <div class="rating-wrapper">
                <span class="rating-value">5.0</span>
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/82000cd10886de19193cd0a417bdb17875e956790d13aa10c22774b1df099f71?placeholderIfAbsent=true&apiKey=ebe69295369b48da88d42feb71473570" alt="5 star rating" class="rating-stars" />
            </div>
            </div>
        </div>
        <h2 class="tour-title">Всё о Пхукет-Тауне: где погулять и что поесть</h2>
        <p class="tour-description">
            По секретным местам столицы острова — <br />
            со знатоком местной истории и гастрономии
        </p>
        <div class="tour-pricing">
            <span class="original-price">$81</span>
            <span class="discounted-price">$69</span>
            <span class="price-info">за группу, 1-4 чел</span>
        </div>
    </div>
</article>
