<div class="container d-flex justify-center">
  <thaipro-header></thaipro-header>
</div>
<main>
  <router-outlet></router-outlet>
  <div class="container d-flex justify-center">
    <thaipro-tour-welcome></thaipro-tour-welcome>
  </div>
  <div class="container d-flex justify-center">
    <thaipro-popular-cities></thaipro-popular-cities>
  </div>
  <div class="container d-flex justify-center">
    <thaipro-tours-carousel [title]="'Популярные экскурсии в Таиланде'" [description]="'Check out some of Thailand best new real estate developments'"></thaipro-tours-carousel>
  </div>
  <div class="gray">
    <div class="container d-flex justify-center">
      <thaipro-tours-carousel [title]="'Индивидуальные экскурсии'" [description]="'Check out some of Thailand best new real estate developments'"></thaipro-tours-carousel>
    </div>
    <div class="container d-flex justify-center">
      <thaipro-tours-carousel [title]="'Групповые экскурсии'" [description]="'Check out some of Thailand best new real estate developments'"></thaipro-tours-carousel>
    </div>
  </div>
  <div class="container d-flex justify-center">
    <thaipro-guides></thaipro-guides>
  </div>
</main>
<div class="gray">
  <div class="container d-flex justify-center">
    <thaipro-footer></thaipro-footer>
  </div>
</div>
